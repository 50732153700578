import React, { useState } from "react";
import {
  Box,
  Paper,
  Typography,
  Stack,
  Tabs,
  Tab,
  useTheme,
  useMediaQuery,
  IconButton,
  Tooltip,
  Button,
} from "@mui/material";
import Player from "./AudioRedesigned";
import { DownloadRedesigned } from "./DownloadRedesigned";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ImageCarousel from "./Carousel";
import useGAEventsTracker from "./GAEventsHook";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

export default function ProductCarousel({ slides }) {
  const GAEventsTracker = useGAEventsTracker("Activity on Brochure");
  const [selectedTab, setSelectedTab] = useState(0);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event, newValue) => {
    console.log("newValue", newValue);
    setSelectedTab(newValue);
  };
  return (
    <Paper
      elevation={0}
      style={{ background: "#fff", height: isSmallScreen ? "" : "90vh" }}
    >
      <Stack
        paddingX={isSmallScreen ? "20px" : "60px"}
        paddingBottom={"20px"}
        direction={isSmallScreen ? "row" : "row"}
        justifyContent={isSmallScreen ? "space-between" : "flex-start"}
      >
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider", width: "100%" }}>
            <Tabs
              value={selectedTab}
              onChange={handleChange}
              aria-label="Basic Tabs"
              width={"100%"}
              variant="fullWidth"
            >
              {slides.map((slide, index) => (
                <Tab
                  key={index}
                  label={slide.title}
                  sx={{
                    fontSize: { sm: "18px", xs: "14px" },
                    fontWeight: "600",
                  }}
                />
              ))}
            </Tabs>
          </Box>
          {slides[selectedTab] && (
            <Box marginTop={"24px"}>
              <Stack
                direction={isSmallScreen ? "column" : "row"}
                alignItems={isSmallScreen ? "flex-start" : "flex-start"}
                gap={"48px"}
              >
                <Stack gap={"48px"} width={isSmallScreen ? "100%" : "60%"}>
                  <Stack direction="row" gap={"16px"} alignItems={"center"}>
                    <Typography variant={isSmallScreen ? "h6" : "h3"}>
                      {slides[selectedTab].heading}
                    </Typography>
                    {/* {slides[selectedTab].audio && <Player url={slides[selectedTab].audio}/>} */}
                  </Stack>
                  <Stack width={"90%"} gap={"24px"}>
                    <Typography variant={isSmallScreen ? "body7" : "body1"}>
                      {slides[selectedTab].description}
                    </Typography>
                    {slides[selectedTab].title === "Corporate Dashboard" ? (
                      <Button
                        onClick={() => {
                          GAEventsTracker(
                            "Corporate dashboard opened",
                            "successfully"
                          );
                          window.open(
                            "https://demo-corporate-dashboard.niroggyan.com"
                          );
                        }}
                        variant={isSmallScreen ? "mobile" : "contained"}
                      >
                        Try Now
                        <ArrowForwardIcon
                          sx={{
                            borderRadius: "12px",
                            background: "white",
                            fill: "#3567D6",
                            width: "44px",
                            height: "44px",
                          }}
                        />
                      </Button>
                    ) : (
                      ""
                    )}
                  </Stack>
                  {slides[selectedTab].sample && (
                    <Stack direction={"row"} gap="16px" alignItems={"center"}>
                      <DownloadRedesigned sample={slides[selectedTab].sample} />
                      <Tooltip placement="right" title={`Preview`}>
                        <IconButton
                          sx={{
                            border: "inset",
                            width: "44px",
                            height: "44px",
                          }}
                          onClick={() => {
                            setOpen(true);
                          }}
                        >
                          <VisibilityIcon fontSize="44px" />
                        </IconButton>
                      </Tooltip>
                      <ImageCarousel
                        open={open}
                        title={slides[selectedTab].title}
                        onClose={handleClose}
                        images={slides[selectedTab].images}
                      />
                    </Stack>
                  )}
                </Stack>
                <Stack
                  width={isSmallScreen ? "100%" : "35%"}
                  sx={{ alignItems: isSmallScreen ? "center" : "" }}
                >
                  {slides[selectedTab].title === "Smart Video Report" ||
                  slides[selectedTab].title === "Video Report" ? (
                    <video
                      controls
                      width={"100%"}
                      style={{ maxWidth: "440px", maxHeight: "440px" }}
                      loop
                    >
                      <source
                        src={
                          "https://niroggyan.s3.ap-south-1.amazonaws.com/brochure-images/svr.mp4"
                        }
                      />
                    </video>
                  ) : (
                    <div style={{ position: "relative" }}>
                      <img
                        style={{
                          width: isSmallScreen ? "90%" : "450px",
                          height: "auto",
                          marginTop: "50px",
                        }}
                        src={slides[selectedTab].illustration}
                        alt={slides[selectedTab].title}
                      />
                      {slides[selectedTab].illustration1 ? (
                        <img
                          style={{
                            width:
                              slides[selectedTab]?.dimension[
                                isSmallScreen ? "widthSm" : "width"
                              ],
                            height: "auto",
                            position: "absolute",
                            top: slides[selectedTab].positions[
                              isExtraSmallScreen
                                ? "topSm"
                                : isSmallScreen
                                ? "topMd"
                                : "top"
                            ],
                            left: slides[selectedTab].positions[
                              isExtraSmallScreen
                                ? "leftSm"
                                : isSmallScreen
                                ? "leftMd"
                                : "left"
                            ],
                          }}
                          src={slides[selectedTab].illustration1}
                          alt={slides[selectedTab].title}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  )}
                </Stack>
              </Stack>
            </Box>
          )}
        </Box>
      </Stack>
    </Paper>
  );
}
