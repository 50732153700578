import {
  Box,
  Button,
  Paper,
  Typography,
  Stack,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import * as React from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ProductCarousel from "./ProductCarouselComponent";
import MeetingModal from "./MeetingModal";
import DeveloperBoardIcon from "@mui/icons-material/DeveloperBoard";
import Diversity2Icon from "@mui/icons-material/Diversity2";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import useGAEventsTracker from "./GAEventsHook";

export default function PersonalProductsPage() {
  const GAEventsTracker = useGAEventsTracker(
    "Activity in Personal Products Page"
  );
  const [showMeeting, setShowMeeting] = React.useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const slides = [
    {
      title: isSmallScreen ? "Video Report" : "Smart Video Report",
      heading: "Smart Video Report",
      description:
        "Experience the future of personalized healthcare with AI Report Summary as Video! We use Generative AI to create a unique diagnostic journey for each patient, complete with individualized concern areas & health tips. Offer the best to your patients with mobile-friendly design, customizable options, realistic avatars, ChatGPT prompts, and support for multiple languages.",
      illustration: "",
      audio:
        "https://niroggyan.s3.ap-south-1.amazonaws.com/brochure-images/audios/AI-video_voice_note.mp3",
      sample: "",
    },
    {
      title: isSmallScreen ? "Analytics" : "Analytics",
      heading: "Analytics",
      description:
        "NiroGyan’s B2B analytics solution empowers diagnostic labs and healthcare providers to transform lab data into actionable insights. Our platform provides real-time analytics on patient demographics, test utilization, and health trends, helping labs optimize resources and improve patient outcomes. With intuitive dashboards and advanced AI, labs can uncover health patterns, monitor KPIs, and enhance patient engagement, turning raw data into a strategic asset for smarter, data-driven decisions.",
      illustration: "personal1.svg",
      audio:
        "https://dn96iqv3kf32j.cloudfront.net/brochure-images/audios/health-risk-audio.mp3",
      sample: "",
    },
    {
      title: isSmallScreen ? "Whatsapp Chatbot" : "Chatbot",
      heading: "Chatbot",
      description:
        "One-way messaging is old & spammy. Go for our two-way Smart WhatsApp Chatbot, which is the most easily-relatable way to engage new-age patients, answer their post-test queries, and enable them to purchase check-ups through the Bot itself.",
      illustration: "personal2.svg",
      audio:
        "https://niroggyan.s3.ap-south-1.amazonaws.com/brochure-images/audios/chatBotAudio.mp3",
      sample: "",
    },
    {
      title: "Corporate Dashboard",
      heading: "Corporate Dashboard",
      description:
        "Gamified population analytics and health insights as a user-friendly dashboard for Corporate leaders. Trusted by Fortune 500 firms for annual health checkup of employees. Also comes with corporate fitness reports",
      illustration: "personal3.svg",
      audio:
        "https://dn96iqv3kf32j.cloudfront.net/brochure-images/audios/coorporateDashaudio.mp3",
      sample: "",
    },
  ];
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        "& > :not(style)": {
          width: "100%",
        },
      }}
    >
      <Paper elevation={0}>
        <Stack
          style={{
            background: "#E9F5FE",
            alignItems: "center",
            height: isSmallScreen ? "auto" : "100vh",
          }}
          paddingX={isSmallScreen ? "20px" : "60px"}
          marginBottom={"60px"}
          paddingTop={"120px"}
          direction={isSmallScreen ? "column" : "row"}
          justifyContent={"space-between"}
          alignItems={isSmallScreen ? "flex-start" : "flex-start"}
          gap={"48px"}
        >
          <Stack width={isSmallScreen ? "100%" : "60%"} gap={"48px"}>
            <Typography variant={isSmallScreen ? "h6" : "h3"}>
              Health Tools
            </Typography>
            <Stack width={"90%"} gap={"10px"}>
              <Typography variant={isSmallScreen ? "body7" : "body1"}>
                New-age products, built with Artificial Intelligence, to engage
                patients outside their reports.
              </Typography>
            </Stack>
            <Button
              onClick={() => {
                setShowMeeting(true);
                GAEventsTracker("Demo Booked");
              }}
              variant={isSmallScreen ? "mobile" : "contained"}
            >
              Book Demo
              <ArrowForwardIcon
                sx={{
                  borderRadius: "12px",
                  background: "white",
                  fill: "#3567D6",
                  width: "44px",
                  height: "44px",
                }}
              />
            </Button>
            <Stack direction={"row"} gap={"16px"}>
              <Paper
                elevation={0}
                sx={{
                  width: "fit-content",
                  padding: "4px 16px 4px 4px",
                  alignItems: "center",
                  borderRadius: "8px",
                  background: "#FFF",
                  transition: "background-color 0.3s ease-in-out",
                  ":hover": {
                    background: "#6c757d30",
                  },
                  boxShadow:
                    " 0px 6.706px 117.362px 0px rgba(46, 52, 54, 0.14), 0px 6.706px 19.56px 0px rgba(46, 52, 54, 0.14)",
                }}
              >
                <DeveloperBoardIcon
                  style={{
                    borderRadius: "8px",
                    fontSize: "44px",
                    background: "#D9EAF3",
                    fill: "#3567D6",
                    marginRight: "10px",
                  }}
                />
                <Typography
                  fontWeight={600}
                  variant={isSmallScreen ? "body11" : "body5"}
                >
                  AI Powered{" "}
                </Typography>
              </Paper>
              <Paper
                elevation={0}
                sx={{
                  width: "fit-content",
                  padding: "4px 16px 4px 4px",
                  alignItems: "center",
                  borderRadius: "8px",
                  background: "#FFF",
                  transition: "background-color 0.3s ease-in-out",
                  ":hover": {
                    background: "#6c757d30",
                  },
                  boxShadow:
                    " 0px 6.706px 117.362px 0px rgba(46, 52, 54, 0.14), 0px 6.706px 19.56px 0px rgba(46, 52, 54, 0.14)",
                }}
              >
                <Diversity2Icon
                  style={{
                    borderRadius: "8px",
                    fontSize: "44px",
                    background: "#D9EAF3",
                    fill: "#3567D6",
                    marginRight: "10px",
                  }}
                />
                <Typography
                  fontWeight={600}
                  variant={isSmallScreen ? "body11" : "body5"}
                >
                  Highly Engaging{" "}
                </Typography>
              </Paper>
              <Paper
                elevation={0}
                sx={{
                  width: "fit-content",
                  padding: "4px 16px 4px 4px",
                  alignItems: "center",
                  borderRadius: "8px",
                  background: "#FFF",
                  transition: "background-color 0.3s ease-in-out",
                  ":hover": {
                    background: "#6c757d30",
                  },
                  boxShadow:
                    " 0px 6.706px 117.362px 0px rgba(46, 52, 54, 0.14), 0px 6.706px 19.56px 0px rgba(46, 52, 54, 0.14)",
                }}
              >
                <AutoFixHighIcon
                  style={{
                    borderRadius: "8px",
                    fontSize: "44px",
                    background: "#D9EAF3",
                    fill: "#3567D6",
                    marginRight: "10px",
                  }}
                />
                <Typography
                  fontWeight={600}
                  variant={isSmallScreen ? "body11" : "body5"}
                >
                  Customisable{" "}
                </Typography>
              </Paper>
            </Stack>
            <MeetingModal
              showMeeting={showMeeting}
              setShowMeeting={setShowMeeting}
            />
          </Stack>

          <Stack width={isSmallScreen ? "40vh" : "35%"}>
            <img
              style={{
                width: isSmallScreen ? "auto" : "100%",
                height: isSmallScreen ? "auto" : "100%",
              }}
              src="personal.svg"
              alt="Personal Products Page"
            />
          </Stack>
        </Stack>
        <ProductCarousel slides={slides} />
      </Paper>
    </Box>
  );
}
